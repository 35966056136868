import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a
              href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru/"
              className="sel"
            >
              {" "}
              &gt; Jak prawidłowo oczyszczać nos podczas kataru?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Jak prawidłowo oczyszczać nos podczas kataru?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Nieżyt nosa (potocznie zwany katarem) to uciążliwa
                    dolegliwość, która dopada każdego. Szczególnie w okresie
                    jesienno-zimowym. W tym czasie jesteśmy narażeni na
                    działanie wirusów, bakterii i&nbsp;innych drobnoustrojów.
                    Podczas kataru warto więc pamiętać o właściwej higienie
                    nosa. Jak powinna ona wyglądać w praktyce?
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Właściwa higiena górnych dróg oddechowych stanowi jeden z
                  najistotniejszych sposobów walki z{" "}
                  <a href="/slowniczek/#przeziebienie" target="_blank">
                    przeziębieniem.
                  </a>{" "}
                  Dlatego, gdy pojawia się katar, zawsze powinniśmy korzystać z
                  chusteczek jednorazowych. Ważne jest, by nie czyścić obu
                  otworów nosowych jednocześnie, gdyż wzrasta wtedy ciśnienie w
                  nosie,{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzielina
                  </a>
                  &nbsp;(zakażona wirusem) przedostaje się do zatok i prowadzi
                  do infekcji uszu (wywołuje stan zapalny). Warto także stosować
                  inhalacje (np. parą wodną lub naparem z olejków eterycznych),
                  szczególnie u dzieci, które nie potrafią samodzielnie
                  wydmuchać nosa. W oczyszczaniu górnych dróg oddechowych
                  pomocne są także wody morskie, które można podzielić na
                  roztwory izotoniczne (pomagają usunąć wydzielinę) i
                  hipertoniczne (rozrzedzają zalegający śluz, którego łatwiej
                  pozbyć się z nosa). W przypadku oczyszczania nosa u małych
                  dzieci można zastosować aspirator lub tradycyjną gruszkę. Te
                  metody są najskuteczniejsze, gdy wydzielina jest rzadka.
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="SUDAFED® sposoby na katar i oczyszczanie nosa podczas kataru"
                    src="/web/kcfinder/uploads/images/shutterstock_666354331.jpg"
                    style={{
                      width: "360px",
                      height: "240px",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Odruch kichania to mechanizm obronny naszego organizmu, który,
                  podobnie jak proces wydmuchiwania nosa, pozwala pozbyć się
                  wirusów z organizmu. Niestety pozbycie się wydzieliny z
                  otworów nosowych często bywa utrudnione, zwłaszcza gdy pojawia
                  się{" "}
                  <a
                    href="/slowniczek/#obrzek_przekrwienie_blony_sluzowej_nosa"
                    target="_blank"
                  >
                    obrzęk błony śluzowej nosa
                  </a>{" "}
                  lub kiedy zalegający śluz gęstnieje. W takiej sytuacji warto
                  zadbać o udrożnienie nosa właściwymi preparatami. Dobrym
                  rozwiązaniem są zarówno aerozole – na przykład{" "}
                  <a href="/produkty/sudafed_xylospray/" target="_blank">
                    Sudafed
                    <span className="s1">
                      <sup>®</sup>
                    </span>{" "}
                    XyloSpray
                  </a>{" "}
                  lub{" "}
                  <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                    Sudafed
                    <span className="s1">
                      <sup>®</sup>
                    </span>{" "}
                    XyloSpray HA
                  </a>
                  , jak i leki w postaci tabletek –{" "}
                  <a href="/produkty/sudafed_tabletki/" target="_blank">
                    Sudafed
                    <span className="s1">
                      <sup>® </sup>
                    </span>
                    Tabletki
                  </a>
                  . Zawierają one substancje (odpowiednio:{" "}
                  <a href="/slowniczek/#ksylometazolina" target="_blank">
                    ksylometazolinę
                  </a>{" "}
                  oraz{" "}
                  <a href="/slowniczek/#pseudoefedryna" target="_blank">
                    pseudoefedrynę
                  </a>
                  )&nbsp;przywracające drożność nosa i ułatwiające
                  oddychanie.&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Prawidłowe oczyszczanie otworów nosowych ma duże znaczenie dla
                  zdrowia, ponieważ nos chroni organizm przed chorobami.
                  Znajdujące się w nim rzęski filtrują przepływające powietrze,
                  ocieplają je, wyłapują zanieczyszczenia (np. cząsteczki
                  alergenów, wirusy, bakterie) i usuwają je z naszego organizmu
                  w odruchu kichania. Nos jest bardzo wrażliwy na czynniki
                  zewnętrzne, które mogą powodować zaburzenia jego poprawnego
                  funkcjonowania. Gdy jesteśmy zmęczeni lub gdy mamy za sobą
                  nieprzespane noce nasz układ odpornościowy jest osłabiony, a
                  to sprzyja powstawaniu infekcji. W takich przypadkach należy
                  szybko podjąć walkę z wrogiem i zastosować odpowiednie
                  leki.&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Katar często postrzegamy jako uciążliwą dolegliwość, jednak
                  warto pamiętać, że nieleczony może prowadzić do poważnych
                  infekcji takich, jak: zapalenie zatok, zapalenie uszu
                  (zwłaszcza u dzieci), zapalenie oskrzeli lub przewlekły{" "}
                  <a href="/slowniczek/#niezyt_nosa" target="_blank">
                    nieżyt nosa
                  </a>
                  .&nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Bibliografia:&nbsp;
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    P. Dąbrowski, <i>Zapalenie górnych dróg oddechowych</i>,
                    [w:] „Przewodnik Lekarza”, 2002, 5, 3, s. 37–42.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    P. Rapiejko, A. Lipiec, <i>Podział nieżytów nosa</i>, [w:]
                    „Alergia”, 2013, nr 2, s. 6-8
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
                <a
                  href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1206612040.jpg")',
                    }}
                  />
                  <span className="title">
                    Katar alergiczny - poznaj i zapobiegaj
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
